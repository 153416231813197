import React from 'react';
import PackageHeroSummary from '@/components/PackageHeroSummary';
import { PackageOption } from '@/libs/v2/utils/getPackageOptions/getPackageOptions';
import {
  Flex,
  Box,
  Heading,
  Text,
  OutlineButton,
} from '@qga/roo-ui/components';
import Skeleton from '@/libs/v2/components/Skeleton';
import { SCREEN_SIZE } from '@/lib/theme';
import styled from '@emotion/styled';
import { Tile } from '@/v2/types/ui';
import PackageInclusions from '../../PackageInclusions';
import { useRouter } from 'next/router';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';
import { PackagePagePriceCashAndPointsOnly } from '@/v2/components/pages/PackageView/components/PackagePagePrice';
import { usePackagePageV2Analytics } from '@/v2/hooks/featureFlags/packagePageV2Analytics/usePackagePageV2Analytics';
import { checkAvailabilityDataLayerEvent } from '../utils/dataLayer';
import { useDataLayerContext } from '@/libs/v2/contexts/DataLayerContext';
import getPrimaryOriginCode from '@/utils/getPrimaryOriginCode';
import useSelectItemEvent from '@/v2/hooks/useSelectItemEvent';
import dataLayer from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';
import useDestination from '@/hooks/useDestination';
import { getOriginName } from '@/v2/utils/getOriginName';

const FlexGrow = styled(Flex)`
  max-width: 100%;
  ${SCREEN_SIZE.SM} {
    max-width: 248px;
  }
`;

const StyledOutlineButton = styled(OutlineButton)`
  padding: 6px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
`;

interface PackageOptionCard {
  id: string;
  packageOption: PackageOption;
  tile: Tile;
  index: number;
}

const PackageOptionCard = ({
  id,
  packageOption,
  tile,
  index,
}: PackageOptionCard) => {
  const { theme, title } = useDestination() || {};
  const { pageName } = useDataLayerContext() || {};
  const { searchQuery } = useSearchQuery();
  const shouldUseNewAnalytics = usePackagePageV2Analytics();
  const shouldUseNewEvents = useGA4Events();

  const inclusions = packageOption?.cheapestOffer?.leadInOffer?.inclusions;
  const cheapestRoute = packageOption?.cheapestOffer?.cheapestRoute;
  const nights = packageOption?.cheapestOffer.cheapestRoute.nights;

  const { push, query } = useRouter();
  const originCode =
    searchQuery?.originCode ||
    getPrimaryOriginCode(tile?.procuredOffer?.origins);

  const destination = tile?.propertyContent?.destination?.title;
  const name = theme ? title : `${title} holidays`;

  const selectItemEventData = useSelectItemEvent({
    originCode,
    property: tile,
    destination,
    name,
    price: cheapestRoute?.price?.total?.amount,
    index,
  });

  const onClickCTA = () => {
    const pathname = `/${tile.propertyContent.destination.name}/properties/${tile.propertyContent.id}/package`;
    if (shouldUseNewAnalytics && !!pageName && !!packageOption.title) {
      checkAvailabilityDataLayerEvent({
        pageName,
        packageOption: packageOption.title,
      });
    }

    if (shouldUseNewEvents) {
      dataLayer.selectItemEvent(selectItemEventData);
      dataLayer.ctaClickEvent({
        itemText: 'CHECK AVAILABILITY',
        url: pathname,
      });
    }

    push({
      pathname,
      query: {
        ...query,
        packageOption: id,
        originCode,
      },
    });
  };

  return (
    <Box
      key={packageOption.id}
      bg="background.card"
      px={['6', null, '6']}
      py={['4', null, '4']}
      data-testid="PACKAGE_OPTION"
      border="2"
      borderColor="greys.alto"
      borderRadius="8px"
      overflow="hidden"
    >
      <Heading.h6 mb={[2, null, 4]} fontWeight="bold">
        {packageOption.title}
      </Heading.h6>
      <Flex
        position="relative"
        flexDirection={['column', null, 'row']}
        justifyContent={[null, null, 'space-between']}
        pb={['2', null, '2']}
      >
        <Flex
          mt={['4', null, '0']}
          mb={['5', null, '0']}
          mr={[null, null, '6']}
          flexDirection={'column'}
          flex="1"
        >
          <PackageInclusions
            inclusions={inclusions}
            procuredOffer={tile?.procuredOffer}
            propertyContent={tile?.propertyContent}
            inAllOptionsModal
          />
        </Flex>
        <FlexGrow gap="16px" flexDirection="column" flex="1">
          <Box borderLeft="3px solid" borderColor="red" pl={5}>
            <Skeleton>
              {!cheapestRoute?.price ? (
                <Heading.h6
                  fontSize="16px"
                  lineHeight="24px"
                  color="brand.primary"
                >
                  Check availability for package pricing
                </Heading.h6>
              ) : (
                <>
                  <Skeleton mb="4">
                    <PackageHeroSummary
                      searchQuery={searchQuery}
                      nights={nights}
                      showNights={true}
                    />
                  </Skeleton>
                  {cheapestRoute?.price?.total && (
                    <PackagePagePriceCashAndPointsOnly
                      displayTravellerTypes={
                        cheapestRoute.displayTravellerTypes
                      }
                      charges={cheapestRoute.price}
                      travellers={cheapestRoute.travellers}
                    />
                  )}
                </>
              )}
            </Skeleton>
            <Skeleton mt="2">
              <Text fontSize="sm" color="greys.steel">
                {`Departing ${
                  getOriginName(searchQuery?.originCode) ||
                  cheapestRoute.origin?.name
                }`}
              </Text>
            </Skeleton>
          </Box>
          <StyledOutlineButton
            type="button"
            variant="primary"
            data-testid="VIEW_ALL_OPTIONS_CTA"
            width="100%"
            mt="2"
            onClick={onClickCTA}
          >
            CHECK AVAILABILITY
          </StyledOutlineButton>
        </FlexGrow>
      </Flex>
    </Box>
  );
};

export default PackageOptionCard;
