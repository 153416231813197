import getAPIRequest from '../../utils/getAPIRequest';

class BFF {
  static getCalendarAvailabilityForProperty(ctx) {
    const instance = getAPIRequest(ctx);

    return (propertyId, startDate, endDate, luxeOnly = false) =>
      instance
        .get(`/calendar/property`, {
          params: {
            propertyId,
            startDate,
            endDate,
            luxeOnly,
          },
        })
        .then((res) => res.data)
        .catch(() => undefined);
  }

  static getCalendarAvailabilityForRoomType(ctx) {
    const instance = getAPIRequest(ctx);

    return (roomTypeId, startDate, endDate, luxeOnly = false) =>
      instance
        .get(`/calendar/room-type`, {
          params: {
            roomTypeId,
            startDate,
            endDate,
            luxeOnly,
          },
        })
        .then((res) => res.data)
        .catch(() => undefined);
  }

  static getMember(ctx) {
    const instance = getAPIRequest(ctx);

    return () =>
      instance
        .get('/member-details/member-details')
        .then((res) => res.data)
        .catch(() => undefined);
  }

  static enablePreview(ctx) {
    const instance = getAPIRequest(ctx);

    return (token) =>
      instance
        .post('/preview/enable', {
          token,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - enablePreview failed!');
        });
  }

  static clearPreview(ctx) {
    const instance = getAPIRequest(ctx);

    return () => instance.post('/preview/clear').then((res) => res.data);
  }
}

export default BFF;
