import { format } from '@/lib/date';
import getAPIRequest from '@/utils/getAPIRequest';
import { API_SERVICE_ERROR } from '../../constants/constants';

export const dateParam = format('yyyy-MM-dd');
export const numberParam = (value) => Number(value) || 0;

class BFF {
  static getPropertiesContent(ctx) {
    const instance = getAPIRequest(ctx);

    return (propertyIds) =>
      instance
        .get('/properties/content', {
          params: { propertyIds: propertyIds.join() },
        })
        .then((res) => res.data);
  }

  static getPropertyContent(ctx) {
    const instance = getAPIRequest(ctx);

    return (propertyId) =>
      instance.get(`/properties/${propertyId}/content`).then((res) => res.data);
  }

  static getPackagesCheapestOffer(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      departureDate,
      returnDate,
      adults,
      children,
      infants,
      originCode,
      destinationCodes,
      travelClass,
      ...propertyIdsByDestination
    }) => {
      const params = {
        departureDate,
        returnDate,
        adults,
        children,
        infants,
        originCode,
        destinationCodes: destinationCodes.join(),
        travelClass,
      };

      destinationCodes.forEach((destinationCode) => {
        params[destinationCode] =
          propertyIdsByDestination[destinationCode].join();
      });

      return instance
        .get('/packages/cheapest-offer', { params })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - getPackagesCheapestOffer failed!');
        });
    };
  }

  static createBooking(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      pointsPay,
      qffPointTiers,
      payment,
      passengers,
      stay,
      airJourney,
      charges,
      deviceFingerprint,
    }) =>
      instance
        .post('/bookings', {
          pointsPay,
          qffPointTiers,
          payment,
          passengers,
          stay,
          airJourney,
          charges,
          deviceFingerprint,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - createBooking failed!');
        });
  }

  static contactUs(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      bookingReference,
      emailAddress,
      givenNames,
      lastName,
      message,
      phone,
      qantasFrequentFlyerNumber,
      title,
    }) =>
      instance
        .post('/cases', {
          bookingReference,
          emailAddress,
          givenNames,
          lastName,
          message,
          phone,
          qantasFrequentFlyerNumber,
          title,
        })
        .then((res) => {
          if (res.data.error) {
            switch (res.data.error) {
              case API_SERVICE_ERROR.EXCEED_API_RATE_LIMIT:
                throw new Error(
                  'Please wait a few minutes before submitting your next request',
                );
              default:
                throw new Error();
            }
          } else if (res.data) return res.data;
          else throw new Error();
        });
  }

  static searchAvailability(ctx) {
    const instance = getAPIRequest(ctx);

    return ({ propertyIds, checkIn, checkOut, adults, children, infants }) =>
      instance
        .get('/availability', {
          params: {
            propertyIds: propertyIds.join(','),
            checkIn: dateParam(checkIn),
            checkOut: dateParam(checkOut),
            adults: numberParam(adults),
            children: numberParam(children),
            infants: numberParam(infants),
          },
        })
        .then((res) => res.data);
  }

  static createQuote(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      propertyId,
      roomTypeId,
      offerId,
      checkIn,
      checkOut,
      adults,
      children,
      infants,
      flights,
      destinationName,
    }) =>
      instance
        .post('/quote', {
          propertyId,
          roomTypeId,
          offerId,
          checkIn: dateParam(checkIn),
          checkOut: dateParam(checkOut),
          adults: numberParam(adults),
          children: numberParam(children),
          infants: numberParam(infants),
          flights,
          destinationName,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - createQuote failed!');
        });
  }

  static getBooking(ctx) {
    const instance = getAPIRequest(ctx);

    return (bookingId) =>
      instance.get(`/bookings/${bookingId}`).then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getBooking failed!');
      });
  }

  static getDestination(ctx) {
    const instance = getAPIRequest(ctx);

    return (destinationName) =>
      instance.get(`/destinations/${destinationName}`).then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getDestination failed!');
      });
  }

  static getDestinations(ctx) {
    const instance = getAPIRequest(ctx);

    return () =>
      instance.get('/destinations').then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getDestinations failed!');
      });
  }

  static getHomePageSettings(ctx) {
    const instance = getAPIRequest(ctx);

    return () =>
      instance.get('/settings/home-page').then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getHomePageSettings failed!');
      });
  }

  static getVPPPage(ctx) {
    const instance = getAPIRequest(ctx);

    return () =>
      instance.get('/vpp-page').then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getVPPPage failed!');
      });
  }

  static getAppSettings(ctx) {
    const instance = getAPIRequest(ctx);

    return () =>
      instance.get('/settings/app').then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getAppSettings failed!');
      });
  }

  static checkVoucher(ctx) {
    const instance = getAPIRequest(ctx);

    return ({ propertyId, amount, code }) =>
      instance
        .post('/voucher/check', {
          code,
          propertyId,
          amount,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - checkVoucher failed!');
        });
  }

  static getVoucherValidation(ctx) {
    const instance = getAPIRequest(ctx);

    return (code) =>
      instance.get(`/voucher/validate/${code}`).then((res) => res.data);
  }

  static getVoucherBalance(ctx) {
    const instance = getAPIRequest(ctx);

    return ({ brand, code, pin }) =>
      instance
        .get(`/voucher/balance/${brand}/${code}/${pin}`)
        .then((res) => res.data);
  }

  static createCallback(ctx) {
    const instance = getAPIRequest(ctx);

    return ({ type, phoneNumber, scheduledTime, data }) =>
      instance
        .post('/callbacks', {
          type,
          phoneNumber,
          scheduledTime,
          data,
        })
        .then((res) => res.data);
  }

  static getDestinationCampaign(ctx) {
    const instance = getAPIRequest(ctx);

    return (destinationName) =>
      instance
        .get(`/destinations/${destinationName}/campaign`)
        .then((res) => res.data);
  }

  static getCampaign(ctx) {
    const instance = getAPIRequest(ctx);

    return (campaignSlug) =>
      instance.get(`/campaigns/${campaignSlug}`).then((res) => res.data);
  }

  static getCampaigns(ctx) {
    const instance = getAPIRequest(ctx);

    return () => instance.get('/campaigns').then((res) => res.data);
  }

  static getProcuredOffer(ctx) {
    const instance = getAPIRequest(ctx);

    return (propertyId) =>
      instance
        .get(`/properties/${propertyId}/procured-offer`)
        .then((res) => res.data);
  }

  static getFaqs(ctx) {
    const instance = getAPIRequest(ctx);

    return () => instance.get('/faqs').then((res) => res.data);
  }
}

export default BFF;
