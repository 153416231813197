import { ORIGINS } from '@/constants';

const getOriginName = (
  code?: string,
  origins?: Array<{ name: string; code: string }>,
) => {
  if (!code) return;
  return (origins || ORIGINS).find((o) => o.code === code)?.name;
};

export default getOriginName;
