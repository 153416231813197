import { useMemo } from 'react';
import useTiers from '@/hooks/useTiers';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';
import { useCustomerSelection } from '@/components/CustomerSelectionProvider';
import getEcommerceItem, {
  Property,
} from '@/v2/utils/dataLayer/eventDataHelpers/getEcommerceItemEventData';
import getPrimaryOrigin from '@/utils/getPrimaryOrigin';
import { ORIGINS } from '@/constants';
import getNights from '@/libs/utils/getNights';
import { SanityApi } from '@/v2/services/sanity/types';
import { getOriginName } from '@/v2/utils/getOriginName';

interface UseSelectItemEventProps {
  property: Property;
  name: string;
  index: number;
  searchType?: 'list' | 'map';
  origins?: SanityApi.ParsedResponse.OriginCodes[];
  originCode?: string;
  destination?: string;
  price?: number;
  itemVariant?: string;
}

const useSelectItemEvent = ({
  origins,
  originCode: code,
  property,
  destination,
  searchType = 'list',
  price,
  name,
  index,
  itemVariant,
}: UseSelectItemEventProps) => {
  const { searchQuery } = useSearchQuery();
  const { usePoints } = useCustomerSelection();
  const tiers = useTiers();

  return useMemo(() => {
    const originCode = code
      ? code
      : searchQuery?.originCode ?? getPrimaryOrigin(origins || ORIGINS)?.code;
    const duration =
      searchQuery?.departureDate && searchQuery?.returnDate
        ? getNights(searchQuery)
        : undefined;

    const item = getEcommerceItem({
      property,
      tiers,
      originCode,
      price,
      index: index + 1,
      itemVariant,
    });

    const selectItemEventData = {
      item,
      usePoints,
      destination,
      searchType,
      origin: getOriginName(originCode, origins),
      name,
      searchQuery,
      duration,
    };

    return selectItemEventData;
  }, [
    code,
    searchQuery,
    origins,
    property,
    tiers,
    price,
    index,
    itemVariant,
    usePoints,
    destination,
    searchType,
    name,
  ]);
};

export default useSelectItemEvent;
